import React from "react";
import styled from "styled-components";
import Image, { FixedObject } from "gatsby-image";

import { SiteSiteMetadataAuthor } from "graphql-types";
import { rhythm } from "../../utils/typography";
import { yearsSince } from "../../utils/timeSince";
import { device } from "../../styles/constants";
import { useAvatar } from "../../hooks";

interface AboutProps {
  author: SiteSiteMetadataAuthor;
}

const About: React.FunctionComponent<AboutProps> = ({
  author,
}): React.ReactElement => {
  const avatar = useAvatar({ width: 200, height: 200 });

  return (
    <Root>
      <Avatar fixed={avatar.childImageSharp.fixed as FixedObject} />

      <Description>
        <h4>Hello World!</h4>
        <p>
          My name is {author.name}, and I’m a software engineer. 
          I have a passion for web and mobile development, cloud services 
          and enabling peers and clients to successfully deliver products 
          and services. I’m a developer by trade, but I love being involved 
          throughout the software development lifecycle. I take a 
          consultative and collaborative approach to every project and team 
          I’m a part of. Win or lose, successful or unsuccessful, we’re 
          in it together. Every day and every project are an opportunity 
          to learn and grow.
        </p>

        <p>
        I’m a learner for life. Knowledge really is power, and I want to 
        be the most powerful man in the world. I want to live outside my 
        comfort zone, meet amazing people, have fascinating conversations, 
        be comfortable with silence and write about all of my experiences. 
        I like to travel, have adventures and eat at different restaurants. 
        I have a freakishly good memory about video games, but I’m absolutely 
        awful at trivia…and bowling.
        </p>

        <p>
        This blog is me - carving out my space in the universe and on the 
        “interwebs.” It’s where I’ll share the things I find interesting and helpful. 
        It’s my outlet for writing and self-expression, for the professional and 
        personal, for the odds and the ends, for you and for me.
        </p>

        <p>
          Thanks for stopping by!
        </p>

        <p>
          JH
        </p>
      </Description>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-gap: ${rhythm(2)};
  margin-top: ${rhythm(0.5)};

  @media ${device.tablet} {
    grid-template-columns: minmax(20%, 200px) 70%;
  }
`;

const Avatar = styled(Image)`
  align-self: center;

  border-radius: 50%;
  width: 150px;
  height: 150px;
  justify-self: center;
  align-self: flex-start;

  @media ${device.tablet} {
    width: auto;
    height: auto;
    justify-self: start;
  }
`;

const Description = styled.section`
  h4 {
    margin-top: ${rhythm(0.5)};
  }
`;

export default About;
